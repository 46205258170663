export const faqs = [
  {
    q: "Do you have gluten free options?",
    a: "Yes, we offer several gluten free entrees on our menu. Gluten free pasta is also available as a subsitute for any unstuffed pasta, and is always prepared separately.",
  },
  {
    q: "Do you have parking?",
    a: "There is metered street parking in the area, as well as several paid lots nearby, the closest being on the corner of Davis St and Benson Ave. Parking is free on Sundays.",
  },
  {
    q: "Do you accomodate private parties?",
    a: "We do not offer a private space for parties. We can accomodate groups up to 35 in our public dining room.",
  },
  {
    q: "Do you offer catering?",
    a: "Our full menu is available for catering and can be customized for any occasion.",
  },
  {
    q: "Do you accept reservations?",
    a: "Yes we accept reservations for indoor dining. Our patio is first come first serve. Please call us at 847-332-2330 for the most accurate availability. Due to our small dining room size, reservations will be held for 10 minutes.",
  },
  {
    q: "Do you offer carry out and delivery?",
    a: "We offer both carry out, and delivery within the Evanston area. Please call us at 847-332-2330 to place your order. ",
  },
];
