import Reservations from "./Reservations/Reservations";
import "./DBInhouse.css";

function DBInhouse() {
  return (
    <div className="inhouse-container">
      <Reservations />
    </div>
  );
}

export default DBInhouse;
