import React from "react";
import "./DashboardNav.css";
import Userfront from "@userfront/core";
import { useSearchParams } from "react-router-dom";
import BackButton from "pages/Dashboard/BackButton";

function DashboardNav(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = props.currentView;
  const authenticated = props.authenticated;

  return (
    Userfront.user.hasRole("owner", {
      tenantId: "7n8p6r7n",
    }) && (
      <div className="dashboard-navbar-wrapper">
        <div className="dashboard-navbar">
          <div className="dbn-left">
            <BackButton />
            <div className="dbn-name">Trattoria Demi Dashboard</div>
          </div>
          <div className="dbn-buttons">
            <button
              className={`dbn-button ${
                currentView === "inhouse" && "dbn-button-active"
              } ${!authenticated && "invisible"}`}
              onClick={() => {
                setSearchParams({});
                searchParams.delete("body");
                searchParams.set("view", "inhouse");
                setSearchParams(searchParams);
              }}
            >
              In house
            </button>
            <button
              className={`dbn-button ${
                currentView === "admin" && "dbn-button-active"
              } ${!authenticated && "invisible"}`}
              onClick={() => {
                searchParams.delete("body");
                searchParams.set("view", "admin");
                setSearchParams(searchParams);
              }}
            >
              Admin
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default DashboardNav;
