import Map from "../components/Map";
import Details from "../components/Details";

function DetailsWrapper() {
  return (
    <div className="home-block hb-even hb-row top-line more-padding">
      <Map />
      <Details />
    </div>
  );
}

export default DetailsWrapper;
