import { convertTo12Hour } from "functions";
import { fadeIn } from "animations";
import { motion, AnimatePresence } from "framer-motion";
import { calendarSvg, peopleSvg, clockSvg } from "svg";
import { dateToString } from "dateUtils";

function FoundTable(props) {
  const { table, setEditing, inRes } = props;
  const { numGuests, date, time } = table;

  return (
    <AnimatePresence>
      <motion.div {...fadeIn} className={`${inRes && "inResTable"}`}>
        <div className="input-text"> Your table </div>
        <div className="found-table">
          <div className="table-info">
            {peopleSvg()}
            {numGuests}
          </div>
          <div className="table-info">
            {calendarSvg()}
            {dateToString(date)}
          </div>
          <div className="table-info">
            {clockSvg()}
            {convertTo12Hour(time)}
          </div>
          <button
            className="edit-btn"
            type="button"
            onClick={() => setEditing(true)}
          >
            Edit
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default FoundTable;
